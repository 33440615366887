import { render, staticRenderFns } from "./ExtremeValues.vue?vue&type=template&id=3cf718d7&scoped=true"
import script from "./ExtremeValues.vue?vue&type=script&lang=js"
export * from "./ExtremeValues.vue?vue&type=script&lang=js"
import style0 from "./ExtremeValues.vue?vue&type=style&index=0&id=3cf718d7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf718d7",
  null
  
)

export default component.exports