<template>
  <v-navigation-drawer
    v-if="['metocean_100m_analysis_points', 'metocean_100m_other_points', 'metocean_1km_points', 'metocean_5km_points', 'metocean_WRA_points2'].includes(activeLocationDataset)"
    class="pl-16"
    permanent
    absolute
    width="40vw"
    color="background"
  >
    <v-container class="account d-flex flex-column">
      <h2 class="h2">
        {{ activeLocationName }}
      </h2>
      <v-btn
        icon
        class="close-button"
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div
        v-if="activeLocationName"
        class="flex-grow-1 py-3 scrollbar"
        align-space-between
      >
        <v-expansion-panels
          v-model="expandedPanels"
          flat
          accordion
          multiple
          color="background"
        >
          <v-expansion-panel v-if="activeLocationDataset != 'metocean_1km_points'">
            <v-expansion-panel-header
              class="h4"
              color="background"
              dark
              data-v-step="5"
            >
              Time series
            </v-expansion-panel-header>
            <v-expansion-panel-content color="background">
              <time-series
                :location-dataset="activeLocationDataset"
                :location-id="$route.params.locationId"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="activeLocationDataset != 'metocean_WRA_points2' && activeLocationDataset != 'metocean_1km_points'">
            <v-expansion-panel-header
              class="h4"
              color="background"
              dark
            >
              Rose plot
            </v-expansion-panel-header>
            <v-expansion-panel-content color="background">
              <rose-plot :location-id="$route.params.locationId" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="activeLocationDataset != 'metocean_WRA_points2' && activeLocationDataset != 'metocean_1km_points'">
            <v-expansion-panel-header
              class="h4"
              color="background"
              dark
            >
              Joint Occurence
            </v-expansion-panel-header>
            <v-expansion-panel-content color="background">
              <joint-occurence :location-id="$route.params.locationId" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="activeLocationDataset != 'metocean_WRA_points2' && activeLocationDataset != 'metocean_1km_points'">
            <v-expansion-panel-header
              class="h4"
              color="background"
              dark
            >
              Persistence
            </v-expansion-panel-header>
            <v-expansion-panel-content color="background">
              <weather-window :location-id="$route.params.locationId" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="activeLocationDataset == 'metocean_100m_analysis_points' || activeLocationDataset == 'metocean_100m_other_points'">
            <v-expansion-panel-header
              class="h4"
              color="background"
              dark
            >
              Extreme values
            </v-expansion-panel-header>
            <v-expansion-panel-content color="background">
              <extreme-values :location-id="$route.params.locationId" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else>
        <p>You are not authorised to view the detailed graphs or the data has not been made available yet.</p>
      </div>
      <div class="flex-shrink-1 bodytext-xs disclaimer">
        Global datasets are generated with great care but may locally contain
        inaccuracies. See the dataset descriptions for more information.
      </div>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash'
import flatten from 'lodash/flatten'
import { mapMutations, mapGetters, mapActions } from 'vuex'

import {
  TimeSeries,
  RosePlot,
  ExtremeValues,
  WeatherWindow,
  JointOccurence
} from '@/components/metocean'

export default {
  components: {
    TimeSeries,
    RosePlot,
    ExtremeValues,
    WeatherWindow,
    JointOccurence
  },
  data() {
    return {
      activeLocationName: '',
      activeLocationDataset: '',
      expandedPanels: []
    }
  },
  computed: {
    ...mapGetters([
      'colors',
      'user',
      'activePointDataPerDataset',
      'getActiveRasterLayer',
      'activeRasterData',
      'activeSummary',
      'getActiveLocationName',
      'getActiveLocationDataset',
      'getExpandedPanels'
    ]),
    datasets() {
      const activePointData = this.activePointDataPerDataset
      const result = Object.keys(activePointData).map((pointId) =>
        _.get(activePointData, [pointId][0])
      )
      return flatten(result)
    },
    hasSerieData() {
      if (_.get(this.datasets, '[0].type') === 'images') {
        return _.get(this.datasets, '[0].imageUrl')
      } else {
        return (
          _.get(this.datasets, '[0].serie') &&
          _.get(this.datasets, '[0].serie').length > 0
        )
      }
    }
  },
  watch: {
    getActiveLocationName: {
      immediate: true,
      handler(newVal) {
        this.activeLocationName = newVal
      }
    },
    getActiveLocationDataset: {
      immediate: true,
      handler(newVal) {
        this.activeLocationDataset = newVal
      }
    },
    getExpandedPanels: {
      immediate: true,
      handler(newVal) {
        this.expandedPanels = newVal
      }
    },
    expandedPanels(newVal) {
      this.$store.commit('setExpandedPanels', newVal)
    }
  },

  mounted() {
    setTimeout(this.updateLocationPanel, 3000)
    this.expandedDatasets = [...Array(this.datasets.length).keys()]
  },

  methods: {
    ...mapMutations([
      'clearActiveLocationIds',
      'setActiveLocationIds',
      'setExpandedPanels'
    ]),
    ...mapActions(['loadPointDataForLocation']),
    updateLocationPanel() {
      const {
        // datasetIds,
        locationId
      } = this.$route.params
      // console.log('Inside updateLocationPanel:', { datasetIds, locationId })
      this.setActiveLocationIds(locationId ? [locationId] : [])
    },
    close() {
      this.$store.commit('setExpandedPanels', [])
      this.$router.push({
        path: `/data/${this.$route.params.datasetIds}`,
        params: { datasetIds: this.$route.params.datasetIds }
      })
    }
  }
}
</script>

<style lang="css" scoped>
.disclaimer {
  text-align: center;
}
.chart {
  height: 500px;
}
</style>
